import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src268204938/src/personal-website/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBanner = makeShortcode("AuthorBanner");
const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>&nbsp;</p>
    <h3>Posts and Articles&nbsp;&nbsp;
  <Link to='posts-and-articles' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">all posts and articles</Button>
  </Link>
    </h3>
    <FeedItems itemsFormat="compact" filterByTags={['post', 'article']} limit={4} mdxType="FeedItems" />
    <p>&nbsp;</p>
    <h3 {...{
      "id": "projects"
    }}>{`Projects`}</h3>
    <FeedItems itemsFormat="compact" filterByTags={['project']} mdxType="FeedItems" />
    <p>&nbsp;</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      